<script>
import LocLink from '../base/LocLink.vue';

export default {
  data() {
    return {
      resources: null,
    };
  },
  components: {
    LocLink
  },
  props: ['items'],
  computed: {},
};
</script>
<template>
  <div v-if="items.imageUri" class="content-banner-image-container"
    :style="'background-image:' + 'url(' + $rt(items.imageUri) + ')'">
    <div class="content-banner-image-box">
      <h1 class="content-banner-title" v-html="$rt(items.title).replace(/[™®©]/g, '<sup>$&</sup>')"></h1>
      <p class="content-banner-text">{{ $rt(items.paragraph) }}</p>
      <div class="content-banner-button-box">
        <LocLink :to="$rt(items.buttonPath)" class="content-banner-image-buttons">{{ $rt(items.buttonText) }}</LocLink>
      </div>
    </div>
  </div>
  <div v-if="items.videoUri" class="content-banner-video-container">
    <div class="video-overlay">
      <h1 class="content-banner-title">{{ $rt(items.title) }}</h1>
      <p class="content-banner-text">{{ $rt(items.paragraph) }}</p>
      <div class="content-banner-button-box">
        <LocLink :to="$rt(items.buttonPath)" class="content-banner-image-buttons">{{ $rt(items.buttonText) }}</LocLink>
      </div>
    </div>
    <video class="video" width="420" height="340" autoplay loop>
      <source src="/images/orbitor.mp4" type="video/mp4" />
    </video>
  </div>
</template>
<style scoped>
.content-banner-image-box {
  text-align: center;
  color: #fff;
  padding: 7% 20px;
  max-width: 900px;
  font-size: 1.45rem;
  margin: 0 auto;
}
.content-banner-video-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.content-banner-image-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 500px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
}
.content-banner-button-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 20px !important;
}
.content-banner-image-buttons {
  cursor: pointer;
  border: 2px solid #f48b41;
  background: #fff;
  width: 260px;
  text-decoration: none;
  text-align: center;
  padding: 0.85em 0;
  margin-bottom: 1em;
  font-size: 15px;
  font-weight: 700;
  display: block;
  transition: all 0.125s;
  border-radius: 1px;
  color: #3b3b3b;
  z-index: 9;
}
.content-banner-image-buttons:hover {
  background-color: #f48b41;
}
.video {
  width: 100%;
  height: 480px;
  object-fit: cover;
}
.video-overlay {
  position: absolute;
  width: 100%;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.content-banner-title {
  color: white;
  font-size: 40px;
  font-weight: 900;
}
.content-banner-text {
  color: white;
  font-size: 25px;
  text-align: center;
  line-height: 1.5em;
  margin-top: 30px;
  margin-bottom: 30px;
}
@media only screen and (max-width: 900px) {
  .video-overlay {
    width: 90%;
  }
}
@media only screen and (max-width: 700px) {
  .content-banner-title {
    font-size: 30px;
  }

  .content-banner-text {
    font-size: 20px;
  }
}
@media only screen and (max-width: 600px) {
  .video-overlay {
    width: 90%;
  }
}
</style>
